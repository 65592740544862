<template>
  <Card class="goals__goals-card" :class="[goal.id? '': 'local']" @click.capture="getHelpForLocal($event)">
    <template v-slot:header>
      <div class="goals-card-header">
        <h3 :id="'goal-id-' + goal.id + '-title'" contenteditable @focusout="updateGoalName">{{ goal.name }}</h3>
      </div>
      <IconNew name="delete" class="card-delete" @click="$emit('delete-card')"/>
    </template>
    <template v-slot:body>
      <div class="goals-card-body">
        <template v-if="goal.type === 'goal'">
          <p>KEY RESULTS:</p>
        </template>
        <template v-else>
          <p>HOW TO AVOID</p>
        </template>
        <div v-if="goal.keyResult && goal.keyResult.length > 0" class="key-results">
          <template v-for="(keyResult, index) in goal.keyResult" :key="keyResult.id">
            <GoalsCardKeyResults
              :name="keyResult.name"
              :complete="keyResult.complete"
              :id="keyResult.id"
              @set-complete="setComplete(index)"
              @set-not-complete="setNotComplete(index)"
              @update-key-result-name="keyResultNameUpdate(index)"
            />
          </template>
        </div>
        <template v-if="goal.type === 'goal'">
          <Button text="+ Add key result" style-modifiers="text" class="new-goal-add-key" @click="addNewKeyResult"/>
        </template>
        <template v-else>
          <Button text="+ Add how to avoid" style-modifiers="text" class="new-risk-add-key" @click="addNewKeyResult"/>
        </template>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/UI-Kit/Card";
import GoalsCardKeyResults from "@/components/GoalsCardKeyResults";
import { updateGoal } from "@/api/goals";
import IconNew from "@/components/UI-Kit/IconNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import Button from "@/components/UI-Kit/Button"

const setup = function(props, {emit}) {

  const setComplete = async function (index) {
    props.goal.keyResult[index].complete = true;
    await updateGoal(props.goal.id, props.workspace, props.goal);
  }

  const setNotComplete = async function (index) {
    props.goal.keyResult[index].complete = false;
    await updateGoal(props.goal.id, props.workspace, props.goal);
  }

  const updateGoalName = async function () {
    const newName = document.querySelector('#goal-id-' + props.goal.id + '-title').textContent;
    await updateGoal(props.goal.id, props.workspace, {name: newName});
  }

  const getHelpForLocal = function (event) {
    if (!props.goal.id) {
      alert("Data is syncing, please wait")
    }
  }

  const keyResultNameUpdate = async function (index) {
    const id = '#key-result-id-' + props.goal.keyResult[index].id
    const name = document.querySelector(id).textContent
    props.goal.keyResult[index].name = name
    await updateGoal(props.goal.id, props.workspace, {keyResult: props.goal.keyResult});
  }

  const addNewKeyResult = async function () {
    props.goal.keyResult.push({
      name: "",
      details: "",
      complete: false
    })
    await updateGoal(props.goal.id, props.workspace, {keyResult: props.goal.keyResult});
  }

  return {
    setComplete,
    setNotComplete,
    updateGoalName,
    keyResultNameUpdate,
    addNewKeyResult,
    getHelpForLocal
  }
};

export default {
  name: "GoalsCard",
  components: {ButtonNew, IconNew, GoalsCardKeyResults, Card, Button },
  props: {
    goal: {
      type: Object,
      required: true
    },
    workspace: {
      type: String,
      required: true
    }
  },
  setup
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.goals__goals-card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  row-gap: 5px;
  text-align: left;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);

  &.local {
    opacity: 0.6;
    cursor: help!important;
  }
}

.goals-card-header{
  padding: 16px;
  h3 {
    outline: none!important;
    border: none!important;

    &:active,
    &:focus {
      border: none;
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
    &:focus:hover {
      cursor: text;
    }
  }
}
.goals-card-body {
  padding: 0 16px 16px 16px;
}

.card-delete {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 16px;
  top: 18px;
  cursor: pointer;
}

.new-goal-add-key,
.new-risk-add-key {
  float: left;
  width: 120px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 15px;

  color: $pxp-gray-color-lighten;

  opacity: 0.8;

}

.new-risk-add-key {
  width: 135px;
}

</style>
